export const revenueIncome = [
  {
    id: "Salary",
    idNumber: 0,
    total: 85000,
    percentage: 0,
  },
  {
    id: "Side Hustles",
    idNumber: 1,
    total: 10000,
    percentage: 0,
  },
];

export const expenseCosts = [
  {
    id: "Rent",
    idNumber: 0,
    total: 25000,
    percentage: 0,
  },
  {
    id: "Food",
    idNumber: 1,
    total: 5000,
    percentage: 0,
  },
  {
    id: "Education",
    idNumber: 2,
    total: 15000,
    percentage: 0,
  },
  {
    id: "Electricity",
    idNumber: 3,
    total: 5000,
    percentage: 0,
  },
  {
    id: "Transportation",
    idNumber: 4,
    total: 4000,
    percentage: 0,
  },
];

function insertColors() {
  // console.log("insertColors function is running");
  // for each item in initialInputs, add a color property with a random color
  expenseCosts.forEach((item) => {
    item.backgroundColor = `rgba(183, 0, 0, 0.2)`;
    item.borderColor = `rgba(183, 0, 0, 1.0)`;
  });
  revenueIncome.forEach((item) => {
    item.backgroundColor = `rgba(190, 255, 184, 0.4)`;
    item.borderColor = `rgba(35, 151, 24, 1.0)`;
  });
}
insertColors();
