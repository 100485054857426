// Package Imports

import React from "react";

// Mapped Components
import BudgetCalculatorComponent from "../components/BudgetCalculatorComponent";
import ShareAndDonate from "../components/ShareAndDonate";
import MainLogo from "../components/MainLogo";

// Custom Component Functions
// import {
//   handleSearchFunctionality,
//   filterFunctionality,
// } from "../api/componentFunctions";

// Static Data
// import { allStates } from "../staticData/allStates";

const BudgetPage = (props) => {
  // const [allLocations, setAllLocations] = useState({});

  return (
    <div className="center flex flex-col bg-white dark:bg-gray-800">
      <div className="bg-white dark:bg-gray-800 my-10 h-5/6 w-full md:w-4/6 rounded-3xl centerDiv ">
        <div className="flex gap-5 py-10 flex-col w-11/12">
          <div className="flex justify-center">
            <MainLogo />
          </div>

          <BudgetCalculatorComponent />

          <ShareAndDonate />
        </div>
      </div>
    </div>
  );
};

export default BudgetPage;
