// Package Imports

import React from "react";

// Mapped Components
import ShareAndDonate from "../components/ShareAndDonate";
import MainLogo from "../components/MainLogo";
// Custom Component Functions
// import {
//   handleSearchFunctionality,
//   filterFunctionality,
// } from "../api/componentFunctions";

// Static Data
// import { allStates } from "../staticData/allStates";

const AboutPage = (props) => {
  // const [allLocations, setAllLocations] = useState({});

  return (
      <div className="center flex flex-col bg-white h-screen dark:bg-gray-800">
        <div className="bg-white dark:bg-gray-800 my-10 h-5/6 w-full md:w-4/6 rounded-3xl centerDiv">
          <div className="flex gap-5 py-10 flex-col w-11/12">
            <div className="flex justify-center">
              <MainLogo />
            </div>

            <div className="flex justify-center">
              <p className="dark:text-white">Pellentesque habitant morbi tristique senectus et netus et malesuada 
              fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies 
              eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. 
              Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet
              est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, 
              commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt 
              condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. 
              Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, 
              neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna 
              eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan 
              porttitor, facilisis luctus, metus
              </p>
            </div>

            <ShareAndDonate />
          </div>
        </div>
      </div>
  );
};

export default AboutPage;
