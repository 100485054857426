// Functions: formatting && parsing && handling search functionality
export const formatter = (value) => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const parser = (value) => {
  return value.replace(/[^0-9]/g, "");
};

export const handleSearchFunctionality = (searchText, state, setState) => {
  setState(
    !searchText
      ? []
      : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)]
  );
};

export const filterFunctionality = (inputValue, option) =>
  option.props.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

// Functions: Manipulating state

export const changeInputNames = (item, value, setState) => {
  setState((prevState) => {
    const updatedArray = prevState.map((obj) => {
      if (obj.idNumber === item.idNumber) {
        return { ...obj, id: value };
      }

      return obj;
    });
    return updatedArray;
  });
};

export const calculateMoney = (item, value, setState) => {
  setState((prevState) => {
    const updatedArray = prevState.map((obj) => {
      if (obj.idNumber === item.idNumber) {
        return { ...obj, total: value };
      }
      return obj;
    });
    return updatedArray;
  });
};

export const calculatePercentage = (setState) => {
  setState((prevState) => {
    // 1) loop through the state array and get the total
    let total = 0;
    prevState.forEach((item) => {
      total += item.total;
    });

    // 2) loop through the state array and calculate the percentage and setState
    const updatedArray = prevState.map((obj) => {
      return { ...obj, percentage: ((obj.total / total) * 100).toFixed(2) };
    });
    return updatedArray;
  });
};

export const addToState = (state, setState, newItemName, type) => {
  let highestIdNumber = 0;
  let borderColor;
  let backgroundColor;
  // get the highest idNumber in the state array
  state.forEach((item) => {
    if (item.idNumber > highestIdNumber) {
      highestIdNumber = item.idNumber;
    }
  });
  highestIdNumber += 1;

  // set the border and background color based on the type
  if (type === "income") {
    borderColor = `rgba(35, 151, 24, 1.0)`;
    backgroundColor = `rgba(190, 255, 184, 0.2)`;
  } else if (type === "expense") {
    borderColor = `rgba(183, 0, 0, 1.0)`;
    backgroundColor = `rgba(183, 0, 0, 0.2)`;
  }

  // add the new item to the state array
  setState([
    ...state,
    {
      id: newItemName,
      idNumber: highestIdNumber,
      total: 0,
      percentage: 0,
      name: newItemName,
      value: 0,
      backgroundColor: backgroundColor,
      borderColor: borderColor,
    },
  ]);
};

export const updateTotalSalary = (incomeState, expenseState, setTotalState) => {
  let totalIncome = 0;
  let totalExpense = 0;
  let totalTaxes = 0;

  incomeState.forEach((item) => {
    totalIncome += item.total;
  });
  expenseState.forEach((item) => {
    totalExpense += item.total;
  });
  totalTaxes += getFederalTaxes(totalIncome);
  setTotalState((totalIncome - totalExpense - totalTaxes).toFixed(2));
};

export const sankeyNodesData = (incomeState, expenseState) => {
  // create an empty dictionary called nodes, and loop through incomeState and expenseState
  // and add the id and name to the nodes dictionary
  let nodes = [];
  nodes.push({ id: "Revenue", nodeColor: "hsl(115, 100%, 36%)" });
  incomeState.forEach((item) => {
    nodes.push({ id: item.id, nodeColor: "hsl(150,70%,50%)" });
  });
  expenseState.forEach((item) => {
    nodes.push({ id: item.id, nodeColor: "hsla(0, 100%, 36%, 0.72)" });
  });

  nodes.push({
    id: "Total Expenses",
    nodeColor: "hsla(0, 100%, 36%, 0.72)",
  });

  nodes.push({
    id: "Federal Taxes",
    nodeColor: "hsla(0, 100%, 36%, 0.72)",
  });

  nodes.push({
    id: "Total Profit",
    nodeColor: "hsla(0, 100%, 36%, 0.72)",
  });
  // nodes.push(
  //   { id: "Total Income", nodeColor: "hsl(115, 100%, 36%)" },
  //   { id: "Federal Taxes", nodeColor: "hsl(115, 100%, 36%)" },
  //   { id: "Total", nodeColor: "hsl(115, 100%, 36%)" }
  // );
  return nodes;
};

export const sankeyLinksData = (incomeState, expenseState) => {
  // create an empty dictionary called links, and loop through incomeState and expenseState
  // and add the source, target, and value to the links dictionary
  let links = [];

  incomeState.forEach((item) => {
    links.push({
      source: item.id,
      target: "Revenue",
      value: Number(item.total.toFixed(0)),
    });
  });

  links.push({
    source: "Revenue",
    target: "Total Expenses",
    value: getTotalExpenses(expenseState),
  });

  expenseState.forEach((item) => {
    links.push({
      source: "Total Expenses",
      target: item.id,
      value: item.total,
    });
  });

  links.push({
    source: "Revenue",
    target: "Federal Taxes",
    value: getFederalTaxes(getTotalIncome(incomeState)),
  });

  links.push({
    source: "Revenue",
    target: "Total Profit",
    value: Number(
      (
        getTotalIncome(incomeState) -
        getTotalExpenses(expenseState) -
        getFederalTaxes(getTotalIncome(incomeState))
      ).toFixed(2)
    ),
  });

  return links;
};

export const updateStatesRadioChange = (
  incomeState,
  expenseState,
  targetValue
) => {
  if (targetValue === "monthly") {
    incomeState.forEach((item) => {
      item.total = Number(Math.ceil(item.total / 12).toFixed(2));
    });

    expenseState.forEach((item) => {
      item.total = Number(Math.ceil(item.total / 12).toFixed(2));
    });
  } else if (targetValue === "yearly") {
    incomeState.forEach((item) => {
      item.total = Number(Math.ceil(item.total * 12).toFixed(2));
    });

    expenseState.forEach((item) => {
      item.total = Number(Math.ceil(item.total * 12).toFixed(2));
    });
  }
};

// Functions: Getting state values && information
export const getTotalIncome = (incomeState) => {
  let totalIncome = 0.0;
  incomeState.forEach((item) => {
    totalIncome += item.total;
  });
  return totalIncome.toFixed(2);
};

export const getTotalExpenses = (expensesState) => {
  let totalExpense = 0.0;
  expensesState.forEach((item) => {
    totalExpense += item.total;
  });
  return totalExpense.toFixed(2);
};

export const getSubtotal = (incomeState, expensesState) => {
  let totalIncome = 0.0;
  incomeState.forEach((item) => {
    totalIncome += item.total;
  });
  let totalExpense = 0.0;
  expensesState.forEach((item) => {
    totalExpense += item.total;
  });
  return (totalIncome - totalExpense).toFixed(2);
};

export const getFederalTaxes = (ivalue) => {
  const value = ivalue;
  if (value <= 9875) {
    return (value * 0.1).toFixed(2);
  }
  if (value <= 40125) {
    return ((value - 9875) * 0.12 + 987.5).toFixed(2);
  }
  if (value <= 85525) {
    return ((value - 40125) * 0.22 + 4617.5).toFixed(2);
  }
  if (value <= 163300) {
    return ((value - 85525) * 0.24 + 14605.5).toFixed(2);
  }
  if (value <= 207350) {
    return ((value - 163300) * 0.32 + 33271.5).toFixed(2);
  }
  if (value <= 518400) {
    return ((value - 207350) * 0.35 + 47367.5).toFixed(2);
  }
  return ((value - 518400) * 0.37 + 156235).toFixed(2);
};

export const getIncomeExpenseStateNames = (incomeState, expenseState) => {
  let allInputNames = [];
  incomeState.forEach((item) => {
    allInputNames.push(item.id);
  });

  expenseState.forEach((item) => {
    allInputNames.push(item.id);
  });
  return allInputNames;
};

export const getIncomeExpenseStateValues = (
  incomeState,
  expenseState,
  type
) => {
  let allInputNames = [];
  if (type === "percentage") {
    incomeState.forEach((item) => {
      allInputNames.push(Number(item.percentage));
    });
    expenseState.forEach((item) => {
      allInputNames.push(Number(item.percentage));
    });
  } else if (type === "value") {
    incomeState.forEach((item) => {
      allInputNames.push(Number(item.total));
    });
    expenseState.forEach((item) => {
      allInputNames.push(Number(item.total));
    });
  }

  return allInputNames;
};

export const getSizeOfAllInputs = (state) => {
  return state.length;
};

export const getBackgroundColor = (incomeState, expenseState) => {
  let colors = [];
  incomeState.forEach((item) => {
    colors.push(item.backgroundColor);
  });
  expenseState.forEach((item) => {
    colors.push(item.backgroundColor);
  });
  return colors;
};

export const getBorderColor = (incomeState, expenseState) => {
  let colors = [];
  incomeState.forEach((item) => {
    colors.push(item.borderColor);
  });
  expenseState.forEach((item) => {
    colors.push(item.borderColor);
  });
  return colors;
};

// Secondary Functions
const mockVal = (str, repeat = 1) => ({
  value: str.repeat(repeat),
});

// Global Functions
export const toogleDarkMode = () => {
  // if set via local storage previously
  if (localStorage.getItem("color-theme")) {
    if (localStorage.getItem("color-theme") === "light") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("color-theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("color-theme", "light");
    }

    // if NOT set via local storage previously
  } else {
    if (document.documentElement.classList.contains("dark")) {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("color-theme", "light");
    } else {
      document.documentElement.classList.add("dark");
      localStorage.setItem("color-theme", "dark");
    }
  }
};
